<ng-container *ngIf="profile$ | async as profile">
  <section class="profile">
    <a
      routerLink="/profile"
      routerLinkActive="active">
      <apex-avatar
        class="amr"
        [user]="profile">
      </apex-avatar>

      <div class="df c">
        {{ profile.name }}

        <ng-container *ngIf="profile.Customer?.name">
          <span class="mat-caption green-background add-margin-top-extra-small">
            {{ profile.Customer.name }}
          </span>
        </ng-container>
      </div>
    </a>
  </section>

  <aside class="amb">
    <apex-assume-user></apex-assume-user>
  </aside>

  <nav class="df c f1">
    <div class="df c f1">
      <section>
        <a
          [routerLink]="['/']"
          routerLinkActive="active">
          <mat-icon>home</mat-icon>
          <T
            str="Home"
            context="sidebar"
            _context="sidebar"></T>
        </a>
      </section>

      <ng-container *ngIf="entitiesRead$ | async as entitiesRead">
        <section *ngIf="entitiesRead > 3">
          <a
            [routerLink]="['/cases']"
            routerLinkActive="active">
            <mat-icon>list</mat-icon>
            <T
              str="Cases"
              context="sidebar"
              _context="sidebar"></T>
          </a>
        </section>
      </ng-container>

      <section>
        <a
          href="https://help.apexapp.io/veiledere-for-boligkunder"
          target="_blank">
          <mat-icon>help</mat-icon>
          <T
            str="Help"
            context="sidebar"
            _context="sidebar"></T>
        </a>
      </section>

      <section>
        <a
          [href]="signOutUrl"
          (click)="signOut()"
          routerLinkActive="active">
          <mat-icon>logout</mat-icon>
          <T
            str="Sign out"
            context="sidebar"
            _context="sidebar"></T>
        </a>
      </section>
    </div>
  </nav>
</ng-container>
