<apex-case-list-item-wrapper
  routerLinkActive="selected-border-inset"
  class="selectable clickable"
  [ngClass]="{
    complete: !!caseItem.completedAt,
    new: !caseItem.completedAt,
    'bg-color-disabled': caseItem.archivedAt,
  }"
  [dense]="dense"
  matRipple
  [routerLink]="[caseItem.id]"
  queryParamsHandling="preserve">
  <ng-container left>
    <div class="mat-headline-6 rm complete left ap w-100">
      <span class="bg-color-normal">
        {{ caseItem.id }}
      </span>
    </div>
  </ng-container>

  <ng-container right>
    <div class="mat-headline-6 rm top-left">
      {{ caseItem.name }}
    </div>

    <div class="df ac jfe top-right">
      <ng-container *ngIf="!caseItem.archivedAt; else archived">
        <mat-icon
          *ngIf="!caseItem.read"
          [matTooltip]="'Unread' | translate">
          priority_high
        </mat-icon>
        <!--      <mat-icon-->
        <!--        *ngIf="caseItem.ChecklistItem?.id"-->
        <!--        [matTooltip]="'Checklist item' | translate">-->
        <!--        horizontal_rule-->
        <!--      </mat-icon>-->
        <!--      <mat-icon-->
        <!--        *ngIf="caseItem.ChecklistId && caseItem.Checklist"-->
        <!--        [matTooltip]="'Checklist' | translate">-->
        <!--        list_alt-->
        <!--      </mat-icon>-->
      </ng-container>

      <ng-template #archived>
        <T str="Archived"></T>
      </ng-template>
    </div>

    <section class="df p-db c w gap main white-space-pre-line apts">
      <section class="t-f1">
        <apex-title-item>
          <T
            title
            str="Apartment"></T>

          {{ apartmentName }}
        </apex-title-item>
      </section>

      <section class="t-f1">
        <apex-title-item *ngIf="caseItem.description">
          <T
            title
            str="Description"></T>

          {{ caseItem.description }}
        </apex-title-item>
      </section>

      <section class="f1 df jfe">
        <apex-title-item *ngIf="caseItem.location">
          <T
            title
            str="Location"></T>

          {{ caseItem.location }}
        </apex-title-item>
      </section>

      <!--      <apex-title-item *ngIf="caseItem.ProjectId || caseItem.ObjectId">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Project"></T>-->

      <!--        <ng-container *ngIf="caseItem.ObjectId">-->
      <!--          <span class="truncate">{{ caseItem.ObjectPath | listArray: ' / ':'name' }}</span>-->
      <!--        </ng-container>-->

      <!--        <ng-container *ngIf="caseItem.ProjectId">-->
      <!--          <span class="truncate">{{ caseItem.Project?.name }}</span>-->
      <!--        </ng-container>-->
      <!--      </apex-title-item>-->

      <!--      <apex-title-item *ngIf="caseItem.ApartmentId">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Apartment"></T>-->

      <!--        <span class="truncate">-->
      <!--          {{ caseItem.Apartment?.name }}-->
      <!--        </span>-->
      <!--      </apex-title-item>-->

      <!--      <apex-title-item *ngIf="caseItem.AgreementId">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Agreement"></T>-->

      <!--        <span class="truncate">-->
      <!--          {{ caseItem.Agreement?.name }}-->
      <!--        </span>-->
      <!--      </apex-title-item>-->

      <!--      <apex-title-item *ngIf="caseItem.TenancyId">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Tenancy"></T>-->

      <!--        <span class="truncate">-->
      <!--          {{ caseItem.Tenancy?.name }}-->
      <!--        </span>-->
      <!--      </apex-title-item>-->

      <!--      <apex-title-item *ngIf="caseItem.MeterId">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Meter"></T>-->

      <!--        <span class="truncate">-->
      <!--          {{ caseItem.Meter?.name }}-->
      <!--        </span>-->
      <!--      </apex-title-item>-->

      <!--      <apex-title-item *ngIf="hasClients">-->
      <!--        <T-->
      <!--          title-->
      <!--          str="Customers"></T>-->

      <!--        <div class="truncate">-->
      <!--          <ng-container *ngFor="let client of clients; last as last">-->
      <!--            {{ client?.name }}-->
      <!--            <ng-container *ngIf="!last">,</ng-container>-->
      <!--          </ng-container>-->
      <!--        </div>-->
      <!--      </apex-title-item>-->
    </section>

    <section class="bottom-left oh apts">
      <apex-file-viewer-preview
        (click)="$event.stopPropagation(); viewFiles()"
        [files]="files"
        [selectable]="false"
        [showFileName]="false">
      </apex-file-viewer-preview>
    </section>

    <section class="bottom-right mat-caption tar df ac jfe">
      <ng-container *ngIf="caseItem.CaseCategory?.name">
        {{ caseItem.CaseCategory.name | translate }}
      </ng-container>
      <ng-container *ngIf="caseItem.Field?.name"> / {{ caseItem.Field.name }} </ng-container>
      <!-- <ng-container *ngIf="caseItem.ObjectField"> / {{ caseItem.ObjectField.fullName }} </ng-container>-->
    </section>
  </ng-container>
</apex-case-list-item-wrapper>
