<div class="display-grid f1 min-height-128">
  <ng-container *ngFor="let image of images | slice: 0 : countLimit">
    <div class="page-break-inside-avoid mat-elevation-z2 ac display-flex f1 column oh min-height-128 min-width-128">
      <ng-container *ngIf="!showPreview">
        <apex-file-preview
          class="f1"
          fit="pad"
          [showName]="false"
          [file]="image.File"
          size="80">
        </apex-file-preview>
      </ng-container>

      <ng-container *ngIf="showPreview">
        <apex-file-preview
          (click)="openPreview(image.File)"
          class="f1 pointer"
          fit="pad"
          [file]="image.File"
          size="96">
        </apex-file-preview>
      </ng-container>

      <div
        *ngIf="showName"
        class="mat-elevation-z2 apxs oh display-grid asfs">
        <div class="text-align-center truncate">{{ image.File.name }}</div>
      </div>
    </div>
  </ng-container>
</div>
